import { useEffect, useRef } from "react";
import { withThrottleOneAtATime } from "@/shared/utils/withThrottleOneAtATime";

export const useThrottleOneAtATime = <R, A extends any[]>(func: (...args: A) => Promise<R>) => {
    const callbackFunction = useRef(func);
    const throttleFunction: (...args: A) => Promise<R> = useRef(
        withThrottleOneAtATime<R, A>((...args) => {
            return callbackFunction.current(...args);
        })
    ).current;

    useEffect(() => {
        callbackFunction.current = func;
    }, [func]);

    return throttleFunction;
};
